<div
	class="margin-top--20 margin-bottom--10"
	fxLayout="row"
	fxLayoutAlign="space-between"
>
	<div fxFlex>
		<p class="text--center">&copy; Braydon Harris</p>
	</div>
	<div fxFlex="nogrow">
		<p class="margin-right--20">V1.0.0</p>
	</div>
</div>
