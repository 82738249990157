<div class="heading-spacer"></div>
<div class="section">
	<div class="about" fxFlexFill fxLayout="row" fxLayoutAlign="center center">
		<div fxFlex="85">
			<a name="about" class="display--none"></a>
			<div fxLayout="row" fxLayoutAlign="start">
				<div>
					<h1 class="mat-display-3">Braydon Harris</h1>
					<br />
					<p class="mat-display-1" [ngStyle.lt-md]="{ 'font-size.px': 16 }">
						My passion is designing, building and deploying apps in scalable and maintainable ways! I like to drive change with technology, focusing on clean design, well-written code, taking the plunge into fun challenging projects.
					</p>
					<div>
						<ul class="personal-links">
							<li>
								<a href="https://github.com/invaderb">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 16 16"
									>
										<path
											d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"
										></path>
									</svg>
								</a>
							</li>
							<li>
								<a
									href="//linkedin.com/in/braydonharris"
									target="_blank"
									><i class="fab fa-linkedin-in"></i
								></a>
							</li>
							<li>
								<a href="//instagram.com/bcreature" target="_blank"
									><i class="fab fa-instagram"></i
								></a>
							</li>
							<li>
								<a
									href="//youtube.com/braydonharris"
									target="_blank"
								></a
								><i class="fab fa-youtube"></i>
							</li>
							<li>
								<a
									href="//drive.google.com/file/d/1ANEykhYZpz7hLME5H3YmG0uRvUBPnFOk/view?usp=sharing"
									target="_blank"
									><i class="far fa-file-pdf"></i
								></a>
							</li>
						</ul>
					</div>
				</div>
				<div fxHide.lt-md>
					<img class="profile" src="assets/images/profile.png" alt="" srcset="">
				</div>
			</div>
		</div>
	</div>
</div>
