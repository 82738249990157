<mat-sidenav-container class="example-container" hasBackdrop="true">
	<mat-sidenav #sidenav mode="over" position="end">
		<div class="mobile-menu">
			<app-navigation display="'block'"></app-navigation>
		</div>
		<p><button mat-button (click)="sidenav.toggle()">Close Menu</button></p>
	</mat-sidenav>

	<mat-sidenav-content>
		<div id="stars"></div>
		<app-header (toggle)="sidenav.toggle($event)"></app-header>

		<app-about></app-about>

		<app-portfolio></app-portfolio>

		<app-skills></app-skills>

		<app-contact></app-contact>

		<app-footer></app-footer>
	</mat-sidenav-content>
</mat-sidenav-container>
