<div class="skewedBoxRight"></div>
<div class="content">
	<a name="portfolio" class="display--none"></a>
	<div
		class="portfolio container"
		fxLayout="row"
		fxLayoutAlign="center center"
	>
		<div fxFlex>
			<div>
				<button
					mat-flat-button
					color="primary"
					type="button"
					data-filter="all"
					class="margin-right--10"
				>
					All
				</button>
				<button
					mat-flat-button
					color="primary"
					type="button"
					data-filter=".web"
					class="margin-right--10"
				>
					Web
				</button>
				<button
					mat-flat-button
					color="primary"
					type="button"
					data-filter=".game"
					class="margin-right--10"
				>
					Game
				</button>
				<button
					mat-flat-button
					color="primary"
					type="button"
					data-filter=".music"
					class="margin-right--10"
				>
					Music
				</button>
			</div>
			<div id="portfoliolist" class="flex flex-space-around flex-wrap margin-bottom--30">
				<div
					*ngFor="let item of items"
					class="mix portfolio-item"
					[ngClass]="item.tags[0]"
				>
					<div class="portfolio-wrapper" style="background-image: url({{item.data.image.url}});">
						<div class="label">
							<div class="label-text">
								<a class="text-title">{{
									item.data.title[0].text
								}}</a>
								<span class="text-category">{{
									item.data.category
								}}</span>
							</div>
							<div class="label-bg"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
