<header>
	<div fxLayout="row" fxLayoutAlign="space-between center" class="header">
		<div class="logo">
			<svg
				class="logo_svg"
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 792 1016"
				style="enable-background: new 0 0 792 1016"
				xml:space="preserve"
			>
				<g>
					<path
						class="st0"
						d="M33.1,768c0-173.8,0-345.5,0-517.3C91.3,210.1,327.2,60.7,385.8,22.4c0,126.7,0,254,0,385.5
                c-17.1,11.3-114.4,76.6-114.4,76.6l114.9,77.5c0,144.5,0,288.7,0,434.6c-24-15.5-117.4-73-140.4-88.7
                C222.1,891.5,58.8,782.2,33.1,768z M310,869.6V592l-104.4-65.7c0,0-73.7,49.3-109.4,73.1v130c38.7,18.1,72.7,43.9,107.7,68.1
                C238.7,821.7,274,845.2,310,869.6z M94.4,517c0,0,168.3-111,215.7-142.5c0-76.1,0-150.7,0-225.8c-15.6,10.2-30.7,19-44.8,29.4
                c-38.4,28.3-78.5,54.2-118.1,80.8c-16.5,11.1-33.8,20.3-51.1,29.8C96.2,361.9,94.4,439.6,94.4,517z"
					/>
					<path
						class="st0"
						d="M475.4,594.2c0,120.2,0,238.9,0,359c-22.4,15.1-45.3,30.6-69.2,46.8c0-326.5,0-652.4,0-979
                c23,15.3,45.2,30,69.2,46c0,147,0,294.8,0,445.1l217.1-144.9c0-51.5,0-103.2,0-154.8l68,37.6c0,173,0,345.5,0,521.3
                c-20.5,12.1-43.2,25.5-69.2,40.9c0-119.9,0-235.9,0-355.4L475.4,594.2z"
					/>
				</g>
			</svg>
		</div>
		<div class="navigation" fxShow fxHide.lt-md="true">
			<app-navigation></app-navigation>
		</div>
		<div class="mobile-nav" fxHide fxShow.lt-md="true">
			<button (click)="toggleMenu()">
				<i class="mat-display-2 fas fa-bars"></i>
			</button>
		</div>
	</div>
</header>
