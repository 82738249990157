<div class="spacer"></div>
<div class="skewedBoxLeft"></div>
<div class="contact content">
	<a name="contact" class="display--none"></a>
	<h3 class="mat-display-2 text--center caps">Send Transmission</h3>
	<div
		class="container contact"
		fxLayout="row"
		fxLayout.lt-md="column"
		fxLayoutAlign="center"
		fxLayoutAlign.lt-md="center center"
	>
		<div fxFlex="20" class="">
			<i id="earth" class="fas fa-globe-americas"></i>
			<div id="satellite-orbit">
				<div (click)="shoot()">
					<i id="satellite" class="fas fa-satellite"></i>
				</div>
			</div>
		</div>
		<div fxFlex="20">
			<div
				class="contact-wrapper margin-bottom--30"
				[ngStyle.lt-md]="'margin-top: 75px'"
			>
				<ul>
					<li class="margin-bottom--15">
						<i class="far fa-envelope"></i>
						<a href="mailto:braydonharris@gmail.com"
							>braydonharris@gmail.com</a
						>
					</li>
					<li class="margin-bottom--15">
						<i class="fas fa-mobile-alt"></i>
						<a href="tel:+(805)419-3037">(805)419-3037</a>
					</li>
					<li class="margin-bottom--15">
						<i class="fab fa-instagram"></i>
						<a href="//instagram.com/bcreature" target="_blank"
							>Instagram</a
						>
					</li>
					<li class="margin-bottom--15">
						<i class="fab fa-linkedin-in"></i>
						<a
							href="//linkedin.com/in/braydonharris/"
							target="_blank"
							>LinkedIn</a
						>
					</li>
					<li class="margin-bottom--15">
						<i class="fab fa-youtube"></i>
						<a href="//youtube.com/braydonharris" target="_blank"
							>YouTube</a
						>
					</li>
					<li class="margin-bottom--15">
						<i class="far fa-file-pdf"></i>
						<a
							href="//drive.google.com/file/d/1ANEykhYZpz7hLME5H3YmG0uRvUBPnFOk/view?usp=sharing"
							target="_blank"
							>Resume</a
						>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
